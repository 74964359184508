<script setup>
const swiper = useSwiper();
</script>
<script>
export default {
  data() {
    return {
      autoplay: 'play',
    };
  },

  methods: {
    keyvisualPlay(e) {
      this.autoplay = 'play';
    },
    keyvisualStop(e) {
      this.autoplay = 'stop';
    },
  },
};
</script>

<template>
  <div class="wb-main-keyvisual-control" :class="autoplay">
    <a
      href="javascript:void(0)"
      class="wb-main-keyvisual-control__play"
      @click="[keyvisualPlay(), swiper.autoplay.start()]"
      >재생</a
    >
    <a
      href="javascript:void(0)"
      class="wb-main-keyvisual-control__stop"
      @click="[keyvisualStop(), swiper.autoplay.stop()]"
      >멈춤</a
    >
  </div>
</template>
<style lang="scss">
.wb-main {
  &-keyvisual {
    &-control {
      position: absolute;
      left: 50%;
      bottom: 20px;
      margin-left: 188px;
      z-index: 50;
      &__play {
        position: absolute;
        bottom: 0;
        left: 0;
        display: block;
        width: 32px;
        height: 32px;
        background: rgba(0, 0, 0, 0.1) url(/icon/KvPlay.svg) no-repeat 50% 50%;
        border-radius: 32px;
        font-size: 0;
        display: none;
      }
      &__stop {
        position: absolute;
        bottom: 0;
        left: 0;
        display: block;
        width: 32px;
        height: 32px;
        background: rgba(0, 0, 0, 0.1) url(/icon/KvStop.svg) no-repeat 50% 50%;
        border-radius: 32px;
        font-size: 0;
        display: none;
      }
      &.stop & {
        &__play {
          display: block;
        }
      }
      &.play & {
        &__stop {
          display: block;
        }
      }
    }
    @media screen and (max-width: $mobile) {
      &-control {
        display: none;
      }
    }
  }
}
</style>
