<script setup lang="ts">
import Tracker from '~/libs/Tracker';

import { useMainStore } from '~/services/main';

import { cn } from '~/components/5x/lib/utils';

const mainStore = useMainStore();

const quickMenu = computed(() => {
  return mainStore.quickMenuList || [];
});

const onClickQuickMenu = (menuName: string) => {
  Tracker['Click Main Quick Menu'](menuName);
};

onServerPrefetch(() => {
  mainStore.fetchQuickMenuList();
});
</script>

<!-- TODO : 993px 부터 ~ 1240 정도까지는 퀵메뉴 오버플로우 히든상태로 스크롤 안됨 (10번째 메뉴 가려짐) 디자인 수정 필요 -->
<template>
  <div
    v-if="quickMenu.length === 0"
    :class="
      cn(
        'mx-auto mt-[50px] mb-15 md:mt-[26px] md:mb-9',
        'grid grid-rows-2 grid-cols-5 gap-y-5 gap-x-3.5 ',
        'md:flex justify-center md:gap-x-8',
      )
    ">
    <div
      v-for="(_, i) in Array.from({ length: 10 })"
      :key="i"
      class="flex flex-col gap-2 justify-center items-center overflow-x-scroll">
      <Skeleton class="h-14 w-14 md:h-20 md:w-20 rounded-[20px] md:rounded-[30px]" />
      <Skeleton class="h-4 w-14 md:w-14 md:h-4.5 mx-auto opacity-80" />
    </div>
  </div>
  <div v-if="quickMenu.length > 0" class="wb-main-quick">
    <div v-for="quickItem in quickMenu" :key="quickItem.id" class="wb-main-quick-item">
      <CustomLink
        :to="quickItem.linkUrl"
        :target="quickItem.linkType === 'N' ? '_blank' : ''"
        :class="{ badge: quickItem.isExistedBadge }"
        @click="onClickQuickMenu(quickItem.title)">
        <img :src="quickItem.imageUrl" />
        <div>{{ quickItem.title }}</div>
      </CustomLink>
    </div>
  </div>
</template>
<style lang="scss">
.wb-main-quick {
  display: flex;
  justify-content: center;
  gap: 32px;
  margin: 50px 0 60px 0;
  // margin: 48px 0 79px 0;
  @media screen and (max-width: $mobile) {
    display: grid;
    row-gap: 20px;
    column-gap: 14px;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    // margin: 16px -6px 38px -6px;
    justify-items: center;
    margin: 26px 0 36px 0;
  }
  .wb-main-quick-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // min-width: 112px;
    width: 80px;
    // height: 80px;
    @media screen and (max-width: $mobile) {
      width: 56px;
      // height: 56px;
    }
    a {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 8px;
      width: 100%;
      height: 100%;

      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px; /* 112.5% */
      color: #051130;
      text-align: center;
      white-space: nowrap;

      @media screen and (max-width: $mobile) {
        font-size: 13px;
        line-height: 16px;
      }
      &.badge {
        position: relative;
        &::after {
          content: '';
          position: absolute;
          top: 2px;
          right: 2px;
          width: 8px;
          height: 8px;
          background-color: #fa3b21;
          border-radius: 50%;
          border: 3px solid #fff;
          @media screen and (max-width: $mobile) {
            font-size: 13px;
            line-height: 16px;
            border: 2px solid #fff;
            top: 1px;
            right: 1px;
            width: 6px;
            height: 6px;
          }
        }
      }
      img {
        display: block;
        position: relative;
        width: 80px;
        height: 80px;
        // width: 100%;
        // height: 100%;
        // background-color: #fff;
        border-radius: 30px;
        object-fit: contain;
        // background: #f7f8f9;
        @media screen and (max-width: $mobile) {
          width: 56px;
          height: 56px;
          border-radius: 20px;
        }
      }
      // div {
      //   display: block;
      //   width: 100%;
      //   text-align: center;
      //   white-space: nowrap;
      //   overflow: hidden;
      //   text-overflow: ellipsis;
      // }
    }
  }
}
</style>
