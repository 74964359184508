<script setup lang="ts">
import { ItemProduct } from '../';
import { useMainStore } from '../../store';

const maiStore = useMainStore();

const isEmpty = $isEmpty;

onMounted(async () => {
  await maiStore.fetchRecentDesignModuleList();
});
</script>

<template>
  <div v-if="!isEmpty(maiStore.recentProductList.items)" class="wb-product-list__item">
    <div class="wb-product-list__title">
      <h4>{{ '최근 업데이트된 강의' }}</h4>
    </div>

    <div class="wb-product-list__list col-05 md-col-02">
      <template v-for="(product, i) in maiStore.recentProductList.items" :key="i">
        <ItemProduct :product="product" :design-module="undefined" :product-index="i" :each-product-index="null" />
      </template>
    </div>
  </div>
</template>
..
