<script setup lang="ts">
// @ts-ignore
import 'skeleton-elements/css/skeleton-elements.css';

import ChannelService from '~/libs/ChannelService';
import Tracker from '~/libs/Tracker';

import Utility from '~/services/Utility';
import { useCommunityStore } from '~/services/community';
import { useUserGnbStore } from '~/services/gnb';
import { useMainStore } from '~/services/main';
import WeolbuStudyProductListSection from '~/services/main/component/product/WeolbuStudyProductListSection.vue';
import { WbBanner } from '~/services/wbBanner';

import {
  MainCommunityIntroSet,
  MainProductList,
  MainQuickMenu,
  MainSwiperBanner,
  MainSwiperSubBanner,
  RecentProductList,
} from '.';

const $route = useRoute();
const gnbStore = useUserGnbStore();
const mainStore = useMainStore();
const communityStore = useCommunityStore();
const {
  public: { frontUrl },
} = useRuntimeConfig();

const {
  state,
  seoData,
  communityCategories,
  designModuleList,
  categoryGnbList,
  gnbMenuList,
  isZeroUser,
  products,
  firstSlideList,
  secondSlideList,
  otherSlideList,
  isHideEvent,
  handleScroll,
} = await useMainContents();

useSeo().setSeoMeta(seoData);

// Structured data for GNB
useSeo().setStructuredItemList(mainStore.gnbMenuList);

// Structured data for Category List
useSeo().setStructuredItemList(gnbStore.getGnbListForStructuredData);

const onClickGoBannerFreeCourse = () => {
  Tracker['Click Banner']('CLASS', 'CLASS_BOTTOM', '');
  window.location.href = '/product?displaySeq=2355';
};

watch(
  () => $route.query,
  async () => {
    if ($route.query.chat && $route.query.chat === 'Y') {
      await ChannelService.showMessenger();
    }
  },
  { immediate: true },
);

onMounted(() => {
  if (mainStore.selectedClassByCategoryList) {
    mainStore.selectedClassByCategoryList.cateSeq = null;
    mainStore.selectedClassByCategoryList.items = [];
  }

  window.addEventListener('scroll', handleScroll);

  const prevPath = Utility.getPathExceptQuery();
  const eventParams = {
    referrer_path: prevPath,
    referrer_url: `${frontUrl}${prevPath}`,
  };
  Tracker['View Main Home'](eventParams);
});

onBeforeUnmount(() => {
  window.removeEventListener('scroll', handleScroll);
});
</script>

<template>
  <!-- SEO 를 위한 추가 -->
  <div style="visibility: hidden; position: absolute">
    <h1>{{ seoData?.data?.items?.seoTitle }}</h1>
    <!-- SEO 를 위해 메인에서 커뮤니티의 게시글로 링크 추가 -->
    <template v-for="category in communityCategories">
      <CustomLink
        v-for="(board, idx) in category.boards"
        :key="`${board.boardName}-${idx}`"
        :to="communityStore.setSubNavigation(category.boardCategoryCd, board.boardSeq)">
        {{ board.boardName }}
      </CustomLink>
    </template>
  </div>

  <MainSwiperBanner />

  <div ref="contentsContainer" class="main-contents-container">
    <div class="main-contents-wrapper">
      <MainQuickMenu />

      <!-- ZERO 유저여부에 따라 layout 순서가 달라짐 -->
      <div class="flex" :class="isZeroUser ? 'flex-col' : 'flex-col-reverse'">
        <ClientOnly>
          <WeolbuStudyProductListSection />
        </ClientOnly>

        <div>
          <div v-for="(designModule, index) in firstSlideList" :key="index" class="wb-main-section">
            <MainProductList :design-module="designModule" :design-module-idx="index" />
          </div>
        </div>
      </div>
    </div>

    <WbBanner design-seq="19" />

    <div class="main-contents-wrapper">
      <div v-for="(designModule, index) in secondSlideList" :key="index" class="main-content-list">
        <MainProductList :design-module="designModule" />
      </div>
    </div>

    <ClientOnly>
      <MainCommunityIntroSet />
    </ClientOnly>

    <div class="main-contents-wrapper">
      <div v-for="(designModule, index) in otherSlideList" :key="index" class="main-content-list">
        <MainProductList :design-module="designModule" />
      </div>
      <MainSwiperSubBanner :products="products" :design-module-idx="5" />
      <RecentProductList />
    </div>
    <div v-if="isHideEvent" class="wb-banner-free">
      <div class="wb-banner-free__form">
        <p class="wb-banner-free__form--text-type1">
          당신이 부담할 비용은
          <img src="/icon/S_Free.png" width="36" height="45" /><span>원</span>
        </p>
        <p class="wb-banner-free__form--text-type2">종잣돈 10배 빨리 모으는 법 모두 알려드려요!</p>
        <Button class="wb-banner-free__button" label="지금 바로 신청하기" @click="onClickGoBannerFreeCourse()" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.main-contents-container {
  margin-bottom: 100px;
  overflow-x: hidden;
}

.main-contents-wrapper {
  width: 1200px;
  margin: 0 auto;
  padding: 0 10px;
}

.main-content-list {
  margin-bottom: 40px;
}

@media screen and (max-width: $md) {
  .main-contents-container {
    margin-bottom: 8px;
    overflow-x: unset;
  }

  .main-contents-wrapper {
    width: auto;
    padding: 0 16px;
  }

  .main-content-list {
    margin-bottom: 32px;
  }

  .community-introduce-set-wrapper {
    width: calc(100%);
    height: unset;
  }
}
</style>

<style lang="scss">
.wb-banner-free {
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 68px;
  @media screen and (max-width: $mobile) {
    margin-bottom: 92px;
  }

  &__form {
    @media screen and (max-width: $mobile) {
      width: 259px;
      /* height: 176px; */
    }
    color: #1c2a4b;
    &--text-type1 {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      font-weight: 500;
      @media screen and (max-width: $mobile) {
        font-size: 16px;
      }
    }
    &--text-type2 {
      font-size: 26px;
      font-weight: 700;
      font-weight: bold;
      @media screen and (max-width: $mobile) {
        font-size: 22px;
      }
    }
  }

  &__button {
    width: 100%;
    height: 100%;
    max-width: 280px;
    max-height: 46px;
    font-weight: 600;
    border-radius: 8px;
    margin-top: 20px;
    @media screen and (max-width: $mobile) {
      margin-top: 16px;
      max-width: 239px;
      font-size: 16px;
      font-weight: 600;
      border-radius: 4px;
    }
  }
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.grid-item {
  width: 100%;
}
</style>
