import { type DesignModule, mainService } from '../main';

interface State {
  designModuleMainList: { items: DesignModule[] };
}

export const useWbBannerStore = defineStore('wb-banner', {
  state: (): State => ({
    designModuleMainList: { items: [] },
  }),
  actions: {
    async fetchDesignModuleList() {
      const res = await mainService.fetchDesignModuleList();
      this.designModuleMainList = res.data.data;
    },
  },
  getters: {},
});
