<script setup lang="ts">
import Tracker, { type ClickCommunityContentProps } from '~/libs/Tracker';

import Utility from '~/services/Utility';
import { type BoardContent, communityService } from '~/services/community';
import { useUserAuthStore } from '~/services/userAuth';

import { type PaginationOptions } from '~/types/global';

type MainBestListState = {
  bestContents: BoardContent[];
};

const { isDesktop } = useMobile();
const userAuthStore = useUserAuthStore();
const router = useRouter();
const route = useRoute();
const runtime = useRuntimeConfig();

const INIT_STATE = () => ({ bestContents: [] });
const state = reactive<MainBestListState>(INIT_STATE());

const moveBestContent = async (item: BoardContent) => {
  if (!userAuthStore.user && item.boardReadAuthCd === '100025') {
    if (!(await Utility.checkLoginThenRedirect(undefined, true))) {
      return;
    }
  }

  if (item.boardContentsSecretYn === 'Y' && (!userAuthStore.user || userAuthStore.user.userId !== item.regId)) {
    await $alert('이 글은 비밀글입니다. 작성자만 조회할 수 있습니다.');
    return;
  }

  const eventParams: ClickCommunityContentProps = {
    boardId: item.boardSeq as string,
    boardName: item.boardName ?? '',
    boardTypeCd: item.boardTypeCd as string,
    boardTypeName: item.boardTypeNm ?? '',
    boardContentsId: item.boardContentsSeq as string,
    boardContentsTitle: item.boardContentsTitle ?? '',
    boardContentsBestYn: item.boardContentsBestYn ?? 'N',
    mustReadYn: 'N',
    viewCount: item.viewCount as string,
    commentCount: item.commentCount as string,
    regId: `${item.regId}`,
    followingYn: 'N',
    pageName: 'CLASS',
    location: 'CURATION_COMMUNITY_1',
    relatedContentsId: '',
    relatedTitle: null,
    likeCount: item.likeCount as string,
    path: route.fullPath,
    url: `${runtime.public.frontUrl}${route.fullPath}`,
  };
  Tracker['Click Community Content'](eventParams);

  router.push(`/community/${item.boardContentsSeq}`);
};

onBeforeMount(async () => {
  try {
    const PAGE_SIZE = isDesktop.value ? 4 : 3;
    const params = {
      pageNo: 1,
      pageSize: PAGE_SIZE,
    };
    const { data: bestContentsData } = await communityService.getBoardContentBestList(params as PaginationOptions);
    const { data: hotContentsData } = await communityService.getBoardHotContents(params as PaginationOptions);
    const bestContents: BoardContent[] = bestContentsData.items;
    const hotContents: BoardContent[] = hotContentsData.items;
    if (bestContents.length < PAGE_SIZE) {
      state.bestContents = [...bestContents, ...hotContents.slice(0, PAGE_SIZE - bestContents.length)];
    } else {
      state.bestContents = bestContents;
    }
  } catch (err) {
    console.log('ERR: Main Best List err: ', err);
  }
});
</script>

<template>
  <div class="best-contents-wrapper">
    <p class="best-contents-title">오늘의 베스트글</p>
    <ul v-if="(state.bestContents?.length ?? 0) > 0" class="best-contents-list" type="best">
      <li
        v-for="(item, idx) in state.bestContents"
        :key="item.boardContentsSeq"
        class="best-contents-list-item"
        @click="moveBestContent(item)">
        <p class="best-contents-number">{{ idx + 1 }}</p>
        <p class="best-contents-preview">
          {{ item.boardContentsTitle }}
        </p>
      </li>
    </ul>
    <Skeleton v-else height="8rem" />
  </div>
</template>

<style lang="scss" scoped>
.best-contents-wrapper {
  display: flex;
  flex-direction: column;
  gap: 18px;
  border-radius: 8px;
  background: var(--base-white, #fff);
  padding: 28px;
  width: 582px;
}

.best-contents-title {
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  padding-bottom: 12px;
  border-bottom: 1px solid #f1f2f4;
}

.best-contents-list {
  display: flex;
  flex-direction: column;
  gap: 18px;
  font-size: 16px;
}

.best-contents-list-item {
  display: flex;
  gap: 12px;
  line-height: 20px;
  cursor: pointer;
}

.best-contents-number {
  color: var(--palette-color-primary-500, #4677f5);
  font-weight: 800;
}

.best-contents-preview {
  font-style: normal;
  font-weight: 500;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  flex: 1 0 0;
}

@media screen and (max-width: $md) {
  .best-contents-wrapper {
    gap: 11px;
    padding: 20px 20px 24px 20px;
    width: 100%;
    border-radius: 4px;
  }

  .best-contents-title {
    font-size: 16px;
    padding-bottom: 8px;
  }

  .best-contents-list {
    gap: 10px;
    font-size: 15px;
  }
}
</style>
