<script setup lang="ts">
import { ItemProduct } from '../';
import Tracker from '../../../../../src/libs/Tracker';
import Utility from '../../../Utility';
import { useUserAuthStore } from '../../../userAuth/store';
import { type DesignModule, type MainProductClickPayload } from '../../type';

const props = withDefaults(
  defineProps<{
    designModule: DesignModule;
    designModuleIdx: number | null;
  }>(),
  {
    designModule: null,
    designModuleIdx: null,
  },
);

const authStore = useUserAuthStore();

const $Utility = Utility;

function getSwiperClass(designModule: DesignModule, index: number) {
  const classes = {
    'wb-lecture': true,
  } as any;

  if (index === 0) {
    classes['wb-lecture--per-view01'] = true;
  } else {
    classes['wb-lecture--per-full'] = designModule.thumnailType === 'B';
    classes['wb-lecture--per-view02'] = designModule.thumnailType === 'S';
  }

  return classes;
}

const handleClickProduct = (product: MainProductClickPayload, designModule: DesignModule, index: number) => {
  Tracker['Click Main Display Product']({
    index,
    displayName: product.displayName,
    productId: product.productSeq || 0,
    productName: product.displayName,
    categoryId: designModule.designSeq,
    categoryName: designModule.designModuleName,
    discountRate: product.discountPrice
      ? Math.round(((product.price - product.discountPrice) / product.price) * 100)
      : 0,
    price: product.price / (product.monthPlan || 1),
    salePrice: product.salePrice,
  });
};

const setTopListTitle = () => {
  if (!authStore.user) {
    return props.designModule.designModuleName;
  }
  return `‘${$Utility.getUserName(authStore.user)}’ 님이 좋아할만한 강의`;
};
</script>

<template>
  <div :class="getSwiperClass(props.designModule, props.designModuleIdx ?? 0)">
    <h2 v-if="props.designModuleIdx === 0" class="wb-lecture__title">
      {{ setTopListTitle() }}
    </h2>

    <h2 v-else class="wb-lecture__title">
      {{ props.designModule.designModuleName }}
    </h2>

    <div class="wb-lecture__swiper">
      <Swiper
        :modules="[SwiperNavigation, SwiperFreeMode]"
        :slides-per-view="props.designModule.thumnailType == 'S' ? 2.3 : 1.3"
        :free-mode="true"
        :loop="false"
        :auto-update="false"
        :auto-destroy="false"
        :delete-instance-on-destroy="false"
        :cleanup-styles-on-destroy="false"
        :space-between="8"
        :navigation="true"
        :breakpoints="{
          '993': {
            slidesPerView: props.designModule.thumnailType == 'S' ? 5 : 4,
            spaceBetween: 16,
          },
        }">
        <SwiperSlide
          v-for="(product, idx) in props.designModule.productList"
          :key="idx"
          class="`swiper-slide-${props.designModule.thumnailType}`">
          <ItemProduct
            :product="product"
            :design-module="props.designModule"
            :product-index="designModuleIdx"
            :each-product-index="idx"
            @click="(product) => handleClickProduct(product, props.designModule, i)" />
        </SwiperSlide>
      </Swiper>
    </div>
  </div>
</template>

<style scoped lang="scss">
// SSR 랜더링 시에 Swiper 정상 노출을 위해 추가
.wb-lecture--per-view01 {
  :deep(.swiper-slide) {
    width: 283px;
    margin-right: 16px;
    @media screen and (max-width: 993px) {
      width: 77%;
      margin-right: 8px;
    }
  }
}
// SSR 랜더링 시에 Swiper 정상 노출을 위해 추가
.wb-lecture--per-view02 {
  :deep(.swiper-slide) {
    width: 223px;
    margin-right: 16px;
    @media screen and (max-width: 993px) {
      width: 38%;
      margin-right: 8px;
    }
  }
}

.wb-lecture {
  position: relative;
  margin-bottom: 40px;
}
.wb-lecture__title {
  position: absolute;
  top: 0;
  left: 0;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  @media screen and (max-width: $md) {
    font-size: 18px;
    line-height: 22px;
  }
}
:deep(.swiper) {
  padding: 40px 0 0 0;
}
</style>
