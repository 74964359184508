<script setup lang="ts">
// TODO :: 추후 배너 기획을 재정립하면 다시 개발해야함
import Tracker from '~/libs/Tracker';

import Utility from '~/services/Utility';
import { useMainStore } from '~/services/main';

import { type DesignModule } from '../';
import { useWbBannerStore } from '../store';

const props = withDefaults(
  defineProps<{
    designSeq?: number | string;
  }>(),
  {
    designSeq: null,
  },
);

const store = useWbBannerStore();
const mainStore = useMainStore();
const { isMobile, isDesktop } = useMobile();

const swiperRef = ref();
const swiperIsBeginningRef = ref<boolean>(true);
const swiperIsEndRef = ref<boolean>(false);
const skeletonArrRef = ref([]);
const bannnerSlideData = computed<DesignModule>(() => {
  return (
    mainStore.designModuleMainList?.items.find((v) => String(v.designSeq) === String(props.designSeq))?.slideList || []
  );
});

const handleSlideNextClick = () => {
  swiperRef.value.slideNext();
};
const handleSlidePrevClick = () => {
  swiperRef.value.slidePrev();
};

const slideChange = (swiper) => {
  nextTick(() => {
    swiperIsBeginningRef.value = swiper.isBeginning;
    swiperIsEndRef.value = swiper.isEnd;
  });
};

const handleClickProduct = (slide, index) => {
  // linkUrl 은 fullUrl 이 온다는 가정으로 협의 되어서 그 방어 코드만 추가함
  if (!slide.linkUrl && slide.linkUrl === '') {
    return;
  }
  const url = new URL(slide.linkUrl);
  if (url.pathname.startsWith('/community/')) {
    Tracker['Click Community Content']({
      designSlideId: slide.designSlideSeq,
      designId: slide.designSeq,
      slideTitle: slide.slideTitle,
      linkUrl: slide.linkUrl,
      location: `CURATION_REVEIW_${index + 1}`,
    });
  }
  if (url.pathname.startsWith('/product')) {
    Tracker['Click Main Display Product']({
      designSlideId: slide.designSlideSeq,
      designId: slide.designSeq,
      slideTitle: slide.slideTitle,
      linkUrl: slide.linkUrl,
      location: `CURATION_REVEIW_${index + 1}`,
    });
  }
  nextTick(() => {
    Utility.openLink(slide.linkUrl, slide.linkType);
  });
};

onMounted(async () => {
  skeletonArrRef.value = Array.from({ length: isMobile.value ? 1 : 4 }, (_, i) => i);
  skeletonArrRef.value = [];
});

onBeforeUnmount(() => {
  swiperRef.value.destroy();
  swiperRef.value = null;
});
</script>
<template>
  <div v-if="bannnerSlideData.length === 0 && skeletonArrRef.length > 0" class="wbs-banner">
    <div class="wbs-banner-body">
      <Skeleton
        v-for="i in skeletonArrRef"
        :key="i"
        border-radius="16px"
        width="283px"
        height="240px"
        class="mb-1"></Skeleton>
    </div>
  </div>
  <div v-if="bannnerSlideData.length > 0" class="wbs-banner">
    <div class="wbs-banner-body">
      <div class="wbs-banner-header">
        <div class="title" :class="{ 'mb-3': isMobile }">월부 수강생 후기</div>
        <div v-if="isDesktop" class="sub-title">
          나도 할 수 있을까 고민이 된다면<br />수강생들의 성공 경험을 들어보세요.
        </div>
        <div v-if="isDesktop && bannnerSlideData.length > 3" class="swiper-buttons">
          <button :class="`swiper-prev ${swiperIsBeginningRef ? 'disable' : ''}`" @click="handleSlidePrevClick"></button
          ><button :class="`swiper-next ${swiperIsEndRef ? 'disable' : ''}`" @click="handleSlideNextClick"></button>
        </div>
      </div>
      <div class="wbs-banner-content">
        <Swiper
          :modules="[SwiperNavigation]"
          :slides-per-view="1.4"
          :free-mode="true"
          :loop="false"
          :space-between="8"
          :navigation="false"
          :breakpoints="{
            '640': {
              slidesPerView: 3,
              spaceBetween: 16,
            },
            // '640': {
            //   slidesPerView: 1.5,
            //   spaceBetween: 8,
            // },
          }"
          @init="
            (swiper) => {
              swiperRef = swiper;
            }
          "
          @slideChange="slideChange">
          <SwiperSlide v-for="(slide, index) in bannnerSlideData" :key="slide.designSlideSeq">
            <NuxtLink
              class="slide-item"
              @click="
                () => {
                  handleClickProduct(slide, index);
                }
              ">
              <Image
                :src="
                  isMobile.value
                    ? Utility.getOptimized(slide.moImageUrl, { q: 80 })
                    : Utility.getOptimized(slide.pcImageUrl, { q: 80 })
                "
                class="slide-img" />
            </NuxtLink>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
:deep(.swiper-slide) {
  width: 283px;
  margin-right: 16px;
  @media screen and (max-width: 993px) {
    width: 30%;
    margin-right: 8px;
  }
  @media screen and (max-width: 640px) {
    width: 64%;
    margin-right: 8px;
  }
}

.wbs-banner {
  width: 100%;
  overflow: hidden;
  background-color: #f1f2f4;
  margin-bottom: 32px;
  &-body {
    width: 1200px;
    margin: 0 auto;
    padding: 40px 10px;
    display: flex;
    justify-content: space-between;
    .wbs-banner-header {
      width: 283px;
      min-width: 283px;
      .title {
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        color: #1c2a4b;
      }
      .sub-title {
        color: #1c2a4b;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        margin-top: 8px;
      }
      .swiper-buttons {
        display: flex;
        gap: 8px;
        margin-top: 40px;
      }
      .swiper-prev {
        width: 32px;
        height: 32px;
        // margin-top: -13px;
        background: rgba(255, 255, 255, 0.2) url(../../../../public/icon/ArrowWhiteLeft.svg) no-repeat 50% 50%;
        border-radius: 26px;
        border: 0;
        padding: 0;
        cursor: pointer;
        &.disable {
          cursor: auto;
          opacity: 0.35;
        }
      }
      .swiper-next {
        // right: 20px;
        width: 32px;
        height: 32px;
        // margin-top: -13px;
        background: rgba(255, 255, 255, 0.2) url(../../../../public/icon/ArrowWhiteRight.svg) no-repeat 50% 50%;
        border-radius: 26px;
        border: 0;
        padding: 0;
        cursor: pointer;
        &.disable {
          cursor: auto;
          opacity: 0.35;
        }
      }
    }
    .wbs-banner-content {
      position: relative;
      flex: 1;
      // max-width: 74.78%;
      max-width: 883px;

      .slide-item {
        display: block;
        position: relative;
        width: 100%;
        height: 100%;
        // max-width: 283px;
        cursor: pointer;
        border-radius: 8px;
        overflow: hidden;
        .slide-img {
          display: block;
          width: 100%;
          :deep(img) {
            display: block;
            vertical-align: middle;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
          }
        }
      }
    }
    @media screen and (max-width: $mobile) {
      .wbs-banner-content {
        max-width: 100%;
        .swiper {
          padding: 0 16px;
        }
      }
    }
  }
  @media screen and (max-width: $mobile) {
    &-body {
      width: 100%;
      padding: 32px 0px;
      display: flex;
      flex-direction: column;
      .wbs-banner-header {
        padding: 0 16px;
        .title {
          font-size: 18px;
          line-height: 22px;
        }
      }
    }
  }
}
</style>
..
