<script setup lang="ts">
import dayjs from 'dayjs';

import Tracker from '~/libs/Tracker';
import { isDateIn, now, safeDateParse } from '~/libs/dateUtils';

import Utility from '~/services/Utility';
import {
  type MainProductClickPayload,
  type WeolbuStudyDesignModule,
  WeolbuStudyImageBanner,
  WeolbuStudyProductItem,
} from '~/services/main';
import { useUserProfileStore } from '~/services/profile';

const props = withDefaults(
  defineProps<{
    designModule: WeolbuStudyDesignModule;
    designModuleIdx: number | null;
  }>(),
  {
    designModule: null,
    designModuleIdx: null,
  },
);

const { isDesktop } = useMobile();
const profile = useUserProfileStore();
const $Utility = Utility;

const swiperClass = computed(() => {
  const classes = {
    'wb-lecture': true,
  } as any;
  const designModule = props.designModule;
  const index = props.designModuleIdx ?? 0;

  if (index === 0) {
    classes['wb-lecture--per-view01'] = true;
  } else {
    classes['wb-lecture--per-full'] = designModule.thumnailType === 'B';
    classes['wb-lecture--per-view02'] = designModule.thumnailType === 'S';
  }

  return classes;
});

const handleClickProduct = (product: MainProductClickPayload, designModule: WeolbuStudyDesignModule, index: number) => {
  Tracker['Click Main Display Product']({
    index,
    displayName: product.displayName,
    productId: product.productSeq || 0,
    productName: product.displayName,
    categoryId: designModule.designSeq,
    categoryName: designModule.designModuleName,
    discountRate: product.discountPrice
      ? Math.round(((product.price - product.discountPrice) / product.price) * 100)
      : 0,
    price: product.price / (product.monthPlan || 1),
    salePrice: product.salePrice,
    payinguserYn: profile.profile?.paymentFunnel === 'ZERO' || !profile.profile ? 'N' : 'Y',
  });
};

const targetSlide = computed(() => {
  return props.designModule?.slideList ? props.designModule?.slideList[0] : undefined;
});

const checkImageBannerOpen = computed(() => {
  if (!targetSlide.value) {
    return false;
  }

  return (
    props.designModule?.isImageBannerUsed &&
    targetSlide.value?.startDate &&
    targetSlide.value?.endDate &&
    isDateIn(targetSlide.value?.startDate, targetSlide.value?.endDate)
  );
});

const productList = computed(() => {
  const nowDate = now();
  return props.designModule.productList.filter((item) => {
    const isLiveEnded = item.liveStudyEndAt && nowDate.isAfter(safeDateParse(item.liveStudyEndAt));
    const isSaleEnded = item.saleEndDt && nowDate.isAfter(safeDateParse(item.saleEndDt));
    return item.useYn === 'Y' && !isLiveEnded && !isSaleEnded;
  });
});
</script>

<template>
  <div :class="swiperClass">
    <h2 class="wb-lecture__title">
      {{ props.designModule.designModuleName }}
    </h2>

    <!-- PC 배너 -->
    <div v-if="isDesktop || true" class="wb-lecture__swiper">
      <Swiper
        :modules="[SwiperNavigation, SwiperFreeMode, SwiperGrid]"
        :slides-per-view="2"
        :free-mode="false"
        :loop="true"
        :space-between="32"
        :slides-per-group="2"
        :navigation="true"
        :breakpoints="{
          '300': {
            slidesPerGroup: 1,
            slidesPerView: 1.05,
            spaceBetween: 16,
            grid: {
              rows: 2,
            },
          },
          '993': {
            slidesPerGroup: 2,
            slidesPerView: 2,
            spaceBetween: 32,
          },
        }"
        class="wb-study-swiper">
        <SwiperSlide v-for="(product, idx) in productList" :key="idx" style="display: flex">
          <WeolbuStudyProductItem
            :product="product"
            :design-module="props.designModule"
            :product-index="designModuleIdx"
            :each-product-index="idx"
            @click="(product) => handleClickProduct(product, props.designModule, i)" />
        </SwiperSlide>
        <!-- 마지막 이미지 배너 -->
        <SwiperSlide v-if="checkImageBannerOpen" style="display: flex; align-items: flex-start">
          <WeolbuStudyImageBanner :design-module="props.designModule" />
        </SwiperSlide>
        <SwiperSlide
          v-if="checkImageBannerOpen && productList.length % 2 === 0"
          style="display: flex; align-items: flex-start">
          <div class="weolbu-study-slide-img">&nbsp;</div>
        </SwiperSlide>
      </Swiper>
    </div>
  </div>
</template>

<style lang="scss">
.wb-study-swiper {
  flex-direction: column;
  display: flex;
  @media screen and (max-width: $md) {
    height: 220px;
  }
  & .swiper-slide {
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (max-width: $md) {
      height: 83px;
    }
  }
}
</style>
