<script setup lang="ts">
import { useMainStore } from '~/services/main';
import WeolbuStudyProductList from '~/services/main/component/product/WeolbuStudyProductList.vue';
import { useUserProfileStore } from '~/services/profile';

const mainStore = useMainStore();
const userProfileStore = useUserProfileStore();

const profileInfo = computed(() => userProfileStore.profile);

const weolbuStudyList = computed(() => {
  return mainStore.getMainWeolbuStudyList.filter((v) => {
    // 구매대상이 전체인 경우, 무조건 보여줌
    if (v.displayTarget === 'ALL') {
      return true;
    }

    // 구매 대상이 전체가 아니고 비회원이라면 안 보여줌
    if (!profileInfo.value) {
      return false;
    }

    // 구매대상이 "구매자"인 경우에는 TR, RP 에게 보여준다
    if (v.displayTarget === 'TR' && ['TR', 'RP'].includes(profileInfo.value?.paymentFunnel as string)) {
      return true;
    }

    // 구매대상이 "미구매자"인 경우, ZERO 유저에게만 보여준다
    if (v.displayTarget === 'ZERO' && profileInfo.value?.paymentFunnel === 'ZERO') {
      return true;
    }

    return false;
  });
});
</script>

<template>
  <div class="wb-main-section__weolbu-study">
    <WeolbuStudyProductList
      v-for="(designModule, index) in weolbuStudyList"
      :key="index"
      :design-module="designModule"
      :design-module-idx="index" />
  </div>
</template>

<style lang="scss"></style>
