import { useThrottleFn } from '@vueuse/core';

import Tracker, { type ViewLocationProps } from '~/libs/Tracker';

import { useCommonStore } from '~/services/common';
import { useUserGnbStore } from '~/services/gnb';
import { useMainStore } from '~/services/main';
import { useUserProfileStore } from '~/services/profile';
import { seoService } from '~/services/seo';
import { useUserAuthStore } from '~/services/userAuth';

type MainEventState = {
  classEventArr: number[];
  communityEventArr: number[];
};

export default async () => {
  const { isDesktop } = useMobile();
  const {
    public: { frontUrl },
  } = useRuntimeConfig();

  const mainStore = useMainStore();
  const authStore = useUserAuthStore();
  const profileStore = useUserProfileStore();
  const commonStore = useCommonStore();
  const gnbStore = useUserGnbStore();

  useHead({
    link: [
      {
        rel: 'canonical',
        href: `${frontUrl}`,
      },
    ],
  });
  useHead({
    htmlAttrs: {
      class: 'page-main layout-show-channeltalk layout-show-topbtn',
    },
  });
  useServerSeoMeta({
    robots: 'all', // index, follow 와 같은 적용
  });

  const INIT_STATE = () => ({
    classEventArr: [],
    communityEventArr: [],
  });

  const state = reactive<MainEventState>(INIT_STATE());

  // 사용자가 결재이력이 있는 유저인지 확인
  const isZeroUser = computed(() => {
    if (authStore.user) {
      return profileStore.profile?.paymentFunnel === 'ZERO';
    }
    return true;
  });

  const [fetchMainSeo, fetchDesignModuleList, fetchGnbMenuList, fetchCategoryGnbList, fetchCommunityCategories] =
    await Promise.all([
      useAsyncData('fetchMainSeo', async () => {
        const res = await seoService.fetchMainSeo();
        commonStore.mainSeo = res.data.items;
        return res;
      }),
      useAsyncData('fetchDesignModuleList', async () => {
        const res = await mainStore.fetchDesignModuleList();
        return res?.data?.items;
      }),
      useAsyncData('fetchGnbMenuList', async () => {
        const res = await mainStore.fetchGnbMenuList();
        return res?.data?.items;
      }),
      useAsyncData('fetchCategoryGnbList', async () => {
        const res = await gnbStore.fetchUserGnbList();
        return res?.data?.items;
      }),
      useCommunityCategories(),
    ]);

  const offset = 1000 * 60 * 60 * 9;
  const koreaNow = new Date(new Date().getTime() + offset);
  const isHideEvent = computed(() => {
    const hideAt = '2023-10-10T18:00:00.000Z';
    return koreaNow <= new Date(hideAt);
  });

  const products = computed(() => mainStore.getMainDesignProductList);
  // oo 님이 좋아할만한강의 / 오늘의 선착순 얼리버드 특가
  const firstSlideList = computed(() => products?.value?.slice(0, 2));
  // 이번 달 BEST / 퇴근 후 월 200 더 버는 부업 클래스
  const secondSlideList = computed(() => products?.value?.slice(2, 4));
  // 요즘 뜨는 부동산 지역 분석 / 소액으로 시작하는 주식 경배 BEST 강의
  const otherSlideList = computed(() => products?.value?.slice(4, products?.value.length));

  const setScrollYByDevice = (idx: number) => {
    const desktopScrollYArr: number[] = [140, 520, 845, 1185, 1985, 2365, 2705, 3685];
    const mobileScrollYArr: number[] = [120, 400, 755, 1105, 2075, 2405, 2705, 3355];
    if (isDesktop.value) {
      return desktopScrollYArr[idx];
    }
    return mobileScrollYArr[idx];
  };

  const handleScroll = useThrottleFn(() => {
    const communityScrollYPc = 1280;
    const communityScrollMobile = 1085;
    const communityScrollY = isDesktop.value ? communityScrollYPc : communityScrollMobile;

    // console.log('window.scrollY', window.scrollY);
    if (window.scrollY > setScrollYByDevice(0) && !state.classEventArr.includes(0)) {
      setViewLocationEvent('CURATION_CLASS', 0);
      state.classEventArr.push(0);
    }
    if (window.scrollY > setScrollYByDevice(1) && !state.classEventArr.includes(1)) {
      setViewLocationEvent('CURATION_CLASS', 1);
      state.classEventArr.push(1);
    }
    if (window.scrollY > setScrollYByDevice(2) && !state.classEventArr.includes(2)) {
      setViewLocationEvent('CURATION_CLASS', 2);
      state.classEventArr.push(2);
    }
    if (window.scrollY > setScrollYByDevice(3) && !state.classEventArr.includes(3)) {
      setViewLocationEvent('CURATION_CLASS', 3);
      state.classEventArr.push(3);
    }
    if (window.scrollY > setScrollYByDevice(4) && !state.classEventArr.includes(4)) {
      setViewLocationEvent('CURATION_CLASS', 4);
      state.classEventArr.push(4);
    }
    if (window.scrollY > setScrollYByDevice(5) && !state.classEventArr.includes(5)) {
      setViewLocationEvent('CURATION_CLASS', 5);
      state.classEventArr.push(5);
    }
    if (window.scrollY > setScrollYByDevice(6) && !state.classEventArr.includes(6)) {
      setViewLocationEvent('CURATION_CLASS', 6);
      state.classEventArr.push(6);
    }
    if (window.scrollY > setScrollYByDevice(7) && !state.classEventArr.includes(6)) {
      setViewLocationEvent('CURATION_CLASS', 7);
      state.classEventArr.push(7);
    }

    if (window.scrollY > communityScrollY && !state.communityEventArr.includes(0)) {
      setViewLocationEvent('CURATION_COMMUNITY', 0);
      state.communityEventArr.push(0);
    }
  }, 1000);

  const setViewLocationEvent = (location: 'CURATION_CLASS' | 'CURATION_COMMUNITY', curationOrder: number) => {
    if (curationOrder === 2) {
      const eventParamsClass: ViewLocationProps = {
        pageName: 'CLASS',
        location: 'CURATION_VIDEO',
        curationTitle: '영상 기반 추천',
        curationOrder,
        displayId: '2226',
        displayName: '열반스쿨 기초반 - 월급 300 직장인이 부동산 투자로 부자되는법',
      };
      Tracker['View Location'](eventParamsClass);
      return;
    }

    if (location === 'CURATION_COMMUNITY') {
      const eventParamsCommunity: ViewLocationProps = {
        pageName: 'CLASS',
        location,
        curationId: '',
        curationTitle: '',
        curationOrder: 3,
      };
      Tracker['View Location'](eventParamsCommunity);
    } else {
      const isAboveThree: boolean = curationOrder >= 3;
      const currentOrder = isAboveThree ? curationOrder + 1 : curationOrder;
      const eventParamsClass: ViewLocationProps = {
        pageName: 'CLASS',
        location,
        curationId: `${products.value[curationOrder]?.designSeq ?? ''}`,
        curationTitle: `${products.value[curationOrder]?.designModuleName ?? ''}`,
        curationOrder: currentOrder,
      };
      Tracker['View Location'](eventParamsClass);
    }
  };

  return {
    state,
    seoData: fetchMainSeo.data.value,
    communityCategories: fetchCommunityCategories.data.value,
    designModuleList: fetchDesignModuleList.data.value,
    gnbMenuList: fetchGnbMenuList.data.value,
    categoryGnbList: fetchCategoryGnbList.data.value,
    isZeroUser,
    handleScroll,
    setViewLocationEvent,
    products,
    firstSlideList,
    secondSlideList,
    otherSlideList,
    isHideEvent,
  };
};
