<script setup lang="ts">
import { stripHtml } from 'string-strip-html';

import Tracker, { type ClickCommunityContentProps } from '~/libs/Tracker';

import { type BoardContentV2, CommunityUserProfileImage, communityService } from '~/services/community';
import { CommunityMainSubTabNumbers, CommunityMainTabNumbers } from '~/services/main/constant';

type MainBestListState = {
  boardContent: BoardContentV2 | null;
};
const INIT_STATE = () => ({ boardContent: null });
const state = reactive<MainBestListState>(INIT_STATE());

const router = useRouter();
const route = useRoute();
const {
  public: { frontUrl },
} = useRuntimeConfig();

const setStripBoardContents = (contentsText: string): string => {
  const stripped = stripHtml(contentsText);
  return stripped.result;
};

const navToExpertColumn = (): void => {
  if (state?.boardContent?.id) {
    const eventParams: ClickCommunityContentProps = {
      boardId: String(state.boardContent.boardId),
      boardName: state.boardContent.boardName ?? '',
      boardTypeCd: state.boardContent.boardTypeCd,
      boardContentsId: String(state.boardContent.id),
      boardContentsTitle: state.boardContent.title ?? '',
      mustReadYn: 'N',
      viewCount: String(state.boardContent.viewCount),
      commentCount: String(state.boardContent.commentCount),
      regId: `${state.boardContent.userId}`,
      followingYn: 'N',
      pageName: 'CLASS',
      location: 'CURATION_COMMUNITY_2',
      relatedContentsId: '',
      relatedTitle: null,
      likeCount: String(state.boardContent.likeCount),
      path: route.fullPath,
      url: `${frontUrl}${route.fullPath}`,
    };
    Tracker['Click Community Content'](eventParams);
    router.push(`/community/${state.boardContent.id}`);
  }
};

onBeforeMount(async () => {
  try {
    const boardCategoryCd = CommunityMainTabNumbers.COLUMN;
    const boardSeq = CommunityMainSubTabNumbers.EXPERT_COLUMN;
    const { data } = await communityService.getBoardContentsWithContentText(boardCategoryCd, Number(boardSeq), {
      pageSize: 1,
      pageNo: 1,
    });

    if (data?.items?.length) {
      state.boardContent = data?.items[0];
    }
  } catch (err) {
    console.log('ERR: ExpertColumn err: ', err);
  }
});
</script>

<template>
  <div class="expert-contents-wrapper" @click="navToExpertColumn()">
    <p class="expert-title">전문가칼럼</p>

    <div v-if="state.boardContent" class="expert-content-set">
      <p class="expert-content-title">{{ state.boardContent.title }}</p>
      <p class="expert-content-description">{{ setStripBoardContents(state.boardContent.boardContentsText ?? '') }}</p>

      <div class="profile-image-set">
        <CommunityUserProfileImage
          :width="30"
          :height="30"
          :profile-img-url="state.boardContent?.userProfileImageUrl" />

        <div class="profile-nickname-set">
          <p class="profile-nickname">{{ state.boardContent.userNickName }}</p>
          <CreatorTagIcon v-if="state.boardContent.userIsCreator" class="creator-tag" />
        </div>
      </div>
    </div>
    <Skeleton v-else height="12rem" />
  </div>
</template>

<style lang="scss" scoped>
.expert-contents-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-radius: 8px;
  background: var(--base-white, #fff);
  padding: 28px;
  width: 283px;
  cursor: pointer;
}

.expert-title {
  color: var(--palette-color-primary-500, #4677f5);
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.expert-content-set {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.expert-content-title {
  overflow: hidden;
  color: var(--neutral-color-solid-1000, #1c2a4b);
  font-family: Pretendard;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  overflow: hidden;
  word-break: break-all;
}

.expert-content-description {
  color: var(--neutral-color-solid-600, #788194);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  overflow: hidden;
  word-break: break-all;
}

.profile-image-set {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 6px;
}

.profile-nickname-set {
  display: flex;
  align-items: center;
  gap: 2px;
}

.profile-nickname {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.14px;
}

.creator-tag {
  width: 16px;
  height: 16px;
}

@media screen and (max-width: $md) {
  .expert-contents-wrapper {
    gap: 10px;
    padding: 20px;
    width: 100%;
    border-radius: 4px;
  }

  .expert-title {
    font-size: 13px;
    font-weight: 600;
  }

  .expert-content-title {
    font-size: 16px;
    line-height: normal;
    -webkit-line-clamp: 1;
  }

  .expert-content-description {
    font-size: 13px;
    line-height: 18px;
    -webkit-line-clamp: 2;
  }
}
</style>
