<script setup lang="ts">
import dompurify from 'isomorphic-dompurify';

import Tracker from '~/libs/Tracker';

import Utility from '~/services/Utility';

import SwiperControls from '~/components/SwiperControls.vue';

import { useMainStore } from '../store';
import { type DesignModuleSlide, DesignModuleType } from '../type';

const isClient = $isClient;
const { isDesktop } = useMobile();
const mainStore = useMainStore();

const isEmpty = $isEmpty;

function openClickBannerCTA(slide: DesignModuleSlide) {
  Tracker['Click Main Big Banner'](slide);
  // Utility.openLink(slide.linkUrl, slide.linkType);
}

const slidesTop = computed(() => {
  const list = mainStore.designModuleMainList.items.filter((v) => v.designModuleType === DesignModuleType.SLIDE);

  if (isEmpty(list)) {
    return null;
  }

  return list[0].slideList.filter((v) => v.useYn === 'Y');
});

// 슬라이드가 3개 이상일때 무한루프
const loopFlag = computed(() => {
  return slidesTop.value && slidesTop.value.length >= 3 ? true : false;
});

const backgroundStyle = (slide: DesignModuleSlide) => {
  return {
    background: Utility.refineColor(isDesktop.value ? slide.pcBackgroundColorHex : slide.moBackgroundColorHex),
  };
};
// 기존 코드 인데 왜 헥사코드를 rgb 로 변환하는지 모르겠음.. hsl로 변환도 아니고, 그냥 헥사코드로 쓰면 되는거 아닌가?
const fontColorStyle = (slide: DesignModuleSlide) => {
  return {
    color: Utility.refineColor(slide.slideTitleColorHex),
  };
};

const getBackgroundImage = (slide: DesignModuleSlide) => {
  const src = isDesktop.value ? slide.pcBackgroundImgUrl : slide.moBackgroundImgUrl;
  return Utility.getOptimized(src, 'BANNER_IMG') || undefined;
};

const getForegroundImage = (slide: DesignModuleSlide) => {
  const src = isDesktop.value ? slide.pcImageUrl : slide.moImageUrl;
  return Utility.getOptimized(src, 'BANNER_IMG') || undefined;
};
</script>

<template>
  <div class="wb-main-slide-banner">
    <div v-if="!isClient()">
      <div class="flex mb-2 justify-center space-x-2.5 md:space-x-4 overflow-hidden">
        <Skeleton class="shrink-0 w-[375px] h-[180px] md:w-[760px] md:h-[340px]"></Skeleton>
        <Skeleton class="shrink-0 w-[375px] h-[180px] md:w-[760px] md:h-[340px]"></Skeleton>
        <Skeleton class="shrink-0 w-[375px] h-[180px] md:w-[760px] md:h-[340px]"></Skeleton>
        <Skeleton class="shrink-0 w-[375px] h-[180px] md:w-[760px] md:h-[340px]"></Skeleton>
        <Skeleton class="shrink-0 w-[375px] h-[180px] md:w-[760px] md:h-[340px]"></Skeleton>
      </div>

      <!-- Swiper가 SSR이 안되서 SEO를 위한 Link를 렌더링-->
      <div class="absolute hidden">
        <CustomLink
          v-for="slide in slidesTop"
          :key="slide.designSeq"
          :to="slide.linkUrl"
          :target="slide.linkType === 'N' ? '_blank' : ''"
          @click="openClickBannerCTA(slide)">
          <div class="title" v-html="dompurify.sanitize(slide.slideTitle)" />
          <div class="sub-title" v-html="dompurify.sanitize(slide.slideSubTitle)"></div>
        </CustomLink>
      </div>
    </div>
    <ClientOnly>
      <Swiper
        v-if="!isEmpty(slidesTop)"
        :modules="[SwiperAutoplay, SwiperPagination, SwiperNavigation]"
        :navigation="true"
        :pagination="{ type: 'fraction' }"
        slides-per-view="1"
        space-between="10"
        :loop="loopFlag"
        :centered-slides="true"
        :initial-slide="0"
        :breakpoints="{
          '390': {
            slidesPerView: 'auto',
            spaceBetween: 10,
          },
          '993': {
            // slidesPerView: 1.4,
            slidesPerView: 'auto',
            spaceBetween: 16,
          },
        }"
        :autoplay="{
          delay: 5000,
        }"
        @init="
          (el) => {
            // 슬라이드 크기보다 화면의 크기가 크면 맨끝 슬라이드가 다시 나오므로 처음으로 이동,
            // 현재 슬라이드가 375px 인데 화면 크기가 2200 이상 일때 나타나는 버그로 보임
            if (el.realIndex !== 0 && el.size > 2200 && el.slides.length > 3) {
              el.slideTo(3, 0, false);
              return;
            }
            // swiper 무한루프 일때 처음에 0번째 슬라이드가 2번째 슬라이드로 이동되는 현상 해결
            if (el.realIndex !== 0 && el.slides.length > 3) {
              el.slideTo(2, 0, false);
            }
          }
        ">
        <SwiperSlide v-for="slide in slidesTop" :key="slide.designSeq">
          <CustomLink
            :to="slide.linkUrl"
            :target="slide.linkType === 'N' ? '_blank' : ''"
            @click="openClickBannerCTA(slide)">
            <div class="color-bg" :style="backgroundStyle(slide)">
              <img
                v-if="isDesktop && slide.pcBackgroundType == 'I'"
                :src="getBackgroundImage(slide)"
                alt="bg"
                loading="lazy" />
              <img
                v-if="!isDesktop && slide.moBackgroundType == 'I'"
                :src="getBackgroundImage(slide)"
                alt="bg"
                loading="lazy" />
            </div>
            <div class="content">
              <div class="content-image">
                <img :src="getForegroundImage(slide)" alt="banner" loading="lazy" />
              </div>
              <div class="text-info">
                <div class="title" :style="fontColorStyle(slide)" v-html="dompurify.sanitize(slide.slideTitle)" />
                <div
                  class="sub-title"
                  :style="fontColorStyle(slide)"
                  v-html="dompurify.sanitize(slide.slideSubTitle)"></div>
              </div>
            </div>
          </CustomLink>
        </SwiperSlide>
        <SwiperControls />
      </Swiper>
    </ClientOnly>
  </div>
</template>
<style lang="scss" scoped>
$sm: 500px; // mobile max
$md: 992px; // tablet max
.wb-main-slide-banner {
  position: relative;
  margin-top: 12px;
  @media screen and (max-width: $md) {
    margin-top: 0;
  }
  .swiper-slide {
    position: relative;
    height: 340px;
    // max-width: 760px;
    width: 760px;
    border-radius: 8px;
    overflow: hidden;
    @media screen and (max-width: $md) {
      border-radius: 0;
      height: 180px;
      width: 375px;
    }
    .color-bg {
      position: relative;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: inline-block;
      }
    }
    .content {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      margin: 0 auto;
      padding: 0;
      display: flex;
      // align-items: center;
      justify-content: flex-end;
      .content-image {
        position: relative;
        // display: block;
        width: 100%;
        height: 100%;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          display: inline-block;
        }
      }
      .text-info {
        position: absolute;
        left: 60px;
        bottom: 60px;
        width: calc(100% - 60px);
        z-index: 1;
        @media screen and (max-width: $md) {
          left: 20px;
          bottom: 28px;
          width: calc(100% - 20px);
        }
        .title {
          margin-bottom: 12px;
          font-weight: 700;
          font-size: 30px;
          line-height: 38px;
          color: #fff;
          word-break: keep-all;
          @media screen and (max-width: $md) {
            font-size: 20px;
            line-height: 26px; /* 130% */
            margin-bottom: 4px;
          }
        }
        .sub-title {
          font-size: 16px;
          line-height: 22px;
          font-weight: 400;
          color: #fff;
          word-break: keep-all;
          @media screen and (max-width: $md) {
            font-size: 13px;
            line-height: 18px;
          }
        }
      }
    }
  }
  /** swiper 기본 css */
  :deep(.swiper-slide-prev) {
    cursor: pointer;
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      opacity: 0.7;
      background: #fff;
      z-index: 1;

      pointer-events: none;
    }
  }
  :deep(.swiper-slide-active) {
    // border: 1px solid blue;
    cursor: pointer;
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 61%, rgba(0, 0, 0, 0.9) 100%);
      pointer-events: none;
    }
  }
  :deep(.swiper-slide-next) {
    cursor: pointer;
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      opacity: 0.7;
      background: #fff;
      z-index: 1;
      pointer-events: none;
    }
  }
  :deep(.swiper-pagination) {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    bottom: 23px;
    left: 50%;
    right: auto;
    margin: 0 0 0 228px;
    // width: auto;
    padding: 0px 10px;
    width: 49px;
    // height: 26px;
    border-radius: 26px;
    // text-align: right;
    color: rgba(255, 255, 255, 0.5);
    font-weight: 600;
    font-size: 14px;
    line-height: 26px;
    letter-spacing: 2px;
    background: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(2px);
    span {
      margin: 0 0;
    }
    .swiper-pagination-current {
      font-weight: 600;
      color: #fff;
    }
    @media screen and (max-width: $md) {
      bottom: 16px;
      height: 22px;
      // left: auto;
      margin: 0 0 0 133px;
      padding: 0 6px;
      // right: 15%;
      width: auto;
    }
    @media screen and (max-width: $sm) {
      bottom: 16px;
      right: 16px;
      font-size: 12px;
      margin: 0;
      left: auto;
    }
  }
  :deep(.swiper-button-prev) {
    position: absolute;
    top: auto;
    bottom: 20px;
    left: 50%;
    margin: 0 0 0 293px;
    width: 32px;
    height: 32px;
    background: rgba(0, 0, 0, 0.1) url(/icon/ArrowWhite.svg) no-repeat 50% 50%;
    border-radius: 32px;
    &:after {
      display: none;
    }
    @media screen and (max-width: $md) {
      display: none;
    }
  }
  :deep(.swiper-button-next) {
    position: absolute;
    top: auto;
    bottom: 20px;
    left: 50%;
    margin: 0 0 0 333px;
    width: 32px;
    height: 32px;
    background: rgba(0, 0, 0, 0.1) url(/icon/ArrowWhite.svg) no-repeat 50% 50%;
    border-radius: 32px;
    transform: rotate(180deg);
    &:after {
      display: none;
    }
    @media screen and (max-width: $md) {
      display: none;
    }
  }
}
</style>
