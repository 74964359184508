<script setup lang="ts">
const { isDesktop } = useMobile();
</script>

<template>
  <div v-if="isDesktop" class="community-introduce-text-wrapper">
    <img class="community-talk-bubble-img" src="/icon/CommunityTalkBubble.png" alt="talk bubble" />
    <p class="community-introduce-text">
      찐부자들이 함께하는, <span class="community-introduce-title">&nbsp;월부 커뮤니티</span>
    </p>
  </div>
  <div v-else class="community-introduce-text-wrapper">
    <p class="community-introduce-text">
      찐부자들이 함께하는, <span class="community-introduce-title">월부 커뮤니티</span>
    </p>
    <img class="community-talk-bubble-img" src="/icon/CommunityTalkBubble.png" alt="talk bubble" />
  </div>
</template>

<style lang="scss" scoped>
.community-talk-bubble-img {
  width: 80px;
  height: 80px;
}

.community-introduce-text-wrapper {
  display: flex;
  align-items: center;
}

.community-introduce-text {
  font-family: Pretendard;

  display: flex;
  color: #ffffff;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
}

.community-introduce-title {
  font-weight: 700;
}

@media screen and (max-width: $md) {
  .community-introduce-text-wrapper {
    width: 100%;
    justify-content: space-between;
  }

  .community-introduce-text {
    flex-direction: column;
    font-size: 16px;
    line-height: 22px;
  }

  .community-introduce-title {
    font-size: 18px;
  }
}
</style>
