<script setup lang="ts">
import { now, safeDateParse } from '~/libs/dateUtils';

import UtilPayment from '~/services/UtilPayment';
import Utility from '~/services/Utility';
import {
  type DisplayProduct,
  type MainProductClickPayload,
  type Product,
  type WeolbuStudyDesignModule,
} from '~/services/main';

const props = defineProps<{
  product: Product | DisplayProduct;
  designModule?: WeolbuStudyDesignModule;
  productIndex?: number;
  eachProductIndex: number;
  hidePrice?: boolean;
}>();

const emit = defineEmits<{
  (e: 'click', product: MainProductClickPayload): void;
}>();

const isEmpty = $isEmpty;
const $Utility = Utility;
const router = useRouter();
const route = useRoute();

const liveStudyStartAtLabel = computed(() =>
  safeDateParse(props.product?.liveStudyStartAt).format('MM/DD(ddd) HH:mm 라이브'),
);
const liveStudyRemainedLabel = computed(() => {
  try {
    const today = now().format('YYYY-MM-DD');
    const startAt = safeDateParse(props.product?.liveStudyStartAt).format('YYYY-MM-DD');
    const dayDiff = safeDateParse(startAt).diff(safeDateParse(today), 'days');
    return `︎● 라이브 D-${dayDiff < 0 ? 0 : dayDiff}`;
  } catch (err) {
    console.log('err :>> ', err);
  }
});
const productMonthPrice = computed(() => {
  return UtilPayment.getMonthlyPrice(props.product as Product, props.product.monthPlan, null, true);
});

const discountPercentage = computed(() => {
  const price = props.product.price;
  const discountPrice = props.product.discountPrice;
  return UtilPayment.getDiscountPercentageValue(price, discountPrice);
});

function movePage() {
  emit('click', { ...props.product, discountPercentage: discountPercentage.value, salePrice: productMonthPrice.value });
  router.push({
    path: '/product',
    query: {
      displaySeq: props.product.displaySeq!,
    },
  });
}
const checkIsLiveStudy = computed(() => {
  return (
    props.designModule?.isAnimate &&
    props.product.isLiveStudy &&
    safeDateParse(props.product.liveStudyStartAt).isAfter(now())
  );
});

const isCourseDayWithPeriod = computed(() => {
  try {
    const today = now().startOf('day'); // 'startOf' 함수를 사용하여 시간 부분을 제거
    if (today.isBetween(props.product.saleStartDt, props.product.saleEndDt, 'day') || props.product.saleType === 'A') {
      return true;
    } else if (today.isBefore(props.product.saleStartDt)) {
      return true;
    } else {
      return false;
    }
  } catch (err) {
    console.log('err :>> ', err);
  }
});
</script>

<template>
  <NuxtLink class="ws-product" @click="movePage">
    <div class="ws-product__thumbnail">
      <div v-if="designModule?.tagViewYn === 'Y'" class="ws-product__thumbnail-badge">
        <Tag v-if="product.todayEndYn == 'Y'" value="오늘마감" style="background: #e4515a; color: #ffffff"></Tag>
        <Tag v-if="product.alarmYn == 'Y'" value="오픈예정" style="background: #ffffff; color: #444444"></Tag>
        <Tag v-if="product.tomorrowEndYn == 'Y'" value="내일마감" style="background: #e4515a; color: #ffffff"></Tag>
      </div>
      <div v-if="checkIsLiveStudy" class="ws-product__thumbnail-badge__live-study">
        <Tag
          :value="liveStudyRemainedLabel"
          :data-text="liveStudyRemainedLabel"
          style="background: #e34935; color: transparent" />
      </div>

      <div class="ws-product__thumbnail-image" :class="{ 'live-class': checkIsLiveStudy }">
        <img :src="Utility.getOptimized(product.displayThumbnailUrl, 'PRODUCT_THUMBNAIL')" alt="" loading="lazy" />
        <div v-if="$Utility.isEndProduct(product)" class="ws-product__soldout">
          <strong>마감</strong>
        </div>
      </div>
    </div>
    <div class="ws-product__detail">
      <h3 class="ws-product__title">
        <p>{{ product.displayName }}</p>
      </h3>

      <div :class="product.reviewCount === 0 && 'iconhide'">
        <p v-if="product.productCateSeq === '118'" class="ws-product__teacher">
          <span v-if="isCourseDayWithPeriod && product.paymentCount > 0">
            {{ Utility.setCommaPer3Digit(product.paymentCount) }}명 신청
          </span>
          <span v-if="isCourseDayWithPeriod && product.paymentCount > 0" style="color: #dddfe4">|&nbsp;</span>
          <span class="ws-product__teacher-name"> {{ product.creatorNames?.join(', ') }} </span>
        </p>
        <p v-else class="ws-product__teacher-score">
          <span v-if="product.reviewCount > 0"
            >{{ product.reviewPoint }}({{ Utility.refineReviewCount(product.reviewCount) }})</span
          >
          <strong v-if="!isEmpty(product.creatorNames)" style="margin-left: 4px">{{
            product.creatorNames?.join(', ')
          }}</strong>
        </p>
      </div>

      <div
        v-if="!hidePrice"
        v-show="designModule == null || (designModule && designModule.priceViewYn == 'Y')"
        class="ws-product__price">
        <p>
          <strong v-if="discountPercentage != 0" class="discount-price-text">
            {{ $Utility.setCommaPer3Digit(discountPercentage) }}%
          </strong>
          <span v-if="$Utility.hasMonthlyPlan(product.monthPlan)">월&nbsp;</span>
          {{ $Utility.setCommaPer3Digit(productMonthPrice) }}원
        </p>
      </div>
      <div v-show="designModule == null || (designModule && designModule.tagViewYn == 'Y')" class="ws-product__tag">
        <Tag v-if="product.originalYn == 'Y'" value="ORIGINAL" style="background: #000000; color: #ffffff"></Tag>
        <Tag
          v-if="product.liveStudyStartAt && product.productCateSeq === '118'"
          :value="liveStudyStartAtLabel"
          style="background: #1d7f8c1a; color: #1d7f8c" />
        <Tag
          v-for="(tag, index) in product.displayTags"
          :key="index"
          :value="tag.tagName"
          :style="{
            background: $Utility.getHex(tag.tagBackColor),
            color: $Utility.getHex(tag.tagFontColor),
          }"></Tag>
      </div>
    </div>
  </NuxtLink>
</template>

<style scoped lang="scss">
@mixin text-clamp($lines: 2, $line-height: false) {
  display: -webkit-box;
  overflow: hidden;
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $lines;
  word-break: break-all;
  @if $line-height {
    max-height: $line-height * $lines * 1px;
  }
}
.ws-product {
  display: flex;
  width: 100%;
  // height: 100%;
  // padding: 0 0 20px 0;
  gap: 16px;
  cursor: pointer;
  @media screen and (max-width: $md) {
    gap: 10px;
  }
  &__detail {
    max-height: 123px;
    overflow: hidden;
    @media screen and (max-width: $md) {
      max-height: 83px;
    }
  }
  &__thumbnail {
    &-badge {
      position: absolute;
      top: 6px;
      left: 6px;
      z-index: 20;
      font-size: 0;
      line-height: 0;
      &__live-study {
        position: absolute;
        top: 3px;
        left: 2.5px;
        z-index: 20;
        font-size: 0;
        line-height: 0;
        @media screen and (max-width: $md) {
          top: 2.5px;
          left: 2.5px;
        }
        :deep(.p-tag) {
          padding: 6px 4px;
          border-radius: 5px 0px;
          overflow: hidden;
          @media screen and (max-width: $md) {
            border-radius: 3px 0px;
          }
        }
        :deep(.p-tag-value) {
          color: #fff;
          text-transform: uppercase;
          font-weight: 700;
          font-family: 'Josefin Sans', sans-serif;
          background: linear-gradient(to right, white 60%, #e34935 40%);
          background-size: auto auto;
          background-clip: border-box;
          background-repeat: no-repeat;
          background-size: 200% 100%;
          color: #fff;
          background-clip: text;
          text-fill-color: transparent;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          animation: textclip 2.5s linear infinite;
          @media screen and (max-width: $md) {
            font-weight: 600;
          }
        }
      }
    }
    &-badge {
      position: absolute;
      top: 6px;
      left: 6px;
      z-index: 20;
      font-size: 0;
      line-height: 0;
    }
    &-image {
      position: relative;
      z-index: 10;
      overflow: hidden;
      border-radius: 8px;
      width: 218px;
      height: 123px;
      @media screen and (max-width: $md) {
        width: 148px;
        height: 83px;
        border-radius: 4px;
      }
      &.live-class {
        &::before {
          content: '';
          position: absolute;
          left: -50%;
          top: -50%;
          width: 200%;
          height: 200%;
          background-repeat: no-repeat;
          background-size:
            100% 50%,
            50% 50%;
          background-position:
            0 0,
            100% 0,
            100% 100%,
            0 100%;
          background-image: linear-gradient(#e34935, #ffffff), linear-gradient(#ffffff, #e34935),
            linear-gradient(#ffffff, #e34935), linear-gradient(#e34935, #ffffff);
          animation: rotate 2.5s linear infinite;
        }

        &::after {
          content: '';
          position: absolute;
          top: 6px;
          left: 6px;
          right: 6px;
          bottom: 6px;
          background: #fff;
        }
        img {
          border-radius: 6px !important;
          width: 213px;
          height: 117px;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          @media screen and (max-width: $md) {
            border-radius: 4px !important;
            width: 143px;
            height: 78px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }

      img {
        border-radius: 8px;
        z-index: 10;
        position: relative;
        width: 218px;
        height: 123px;
        @media screen and (max-width: $md) {
          border-radius: 4px;
          width: 148px;
          height: 83px;
        }
      }
    }
  }
  &__title {
    @include text-clamp(2, 22);
    margin-bottom: 8px;
    overflow: hidden;
    color: #1c2a4b;
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    @media screen and (max-width: $md) {
      @include text-clamp(1);
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 4px;
    }
  }
  &__teacher {
    @include text-clamp(1);
    margin-bottom: 8px;
    color: #8790a1;
    font-family: Pretendard;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 123.077% */
    @media screen and (max-width: $md) {
      font-size: 13px;
      font-weight: 500;
      margin-bottom: 4px;
    }
    &-name {
      font-weight: 500;
    }
  }
  &__teacher-score {
    display: flex;
    align-items: center;
    margin-right: 6px;
    margin-bottom: 8px;
    color: #8790a1;
    font-family: Pretendard;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 123.077% */
    @media screen and (max-width: $md) {
      margin-bottom: 4px;
    }
    &:before {
      content: '';
      display: block;
      min-width: 16px;
      width: 16px;
      height: 16px;
      margin-right: 3px;
      background: url(../public/icon/Star.svg);
      background-size: cover;
    }
    strong {
      @include text-clamp(1);
      font-weight: 500;
    }
  }
  &__price {
    margin-bottom: 8px;
    font-family: Pretendard;
    font-style: normal;
    font-size: 16px;
    font-weight: 700;
    line-height: normal;
    p {
      color: #1c2a4b;
    }
    @media screen and (max-width: $md) {
      margin-bottom: 4px;
      font-size: 14px;
    }
  }
  &__tag {
    font-size: 0;
    &.p-tag {
      margin: 0 4px 4px 0;
    }
    :deep(.p-tag) {
      margin-right: 4px;
      margin-bottom: 4px;
    }
  }
}
@keyframes rotate {
  100% {
    transform: rotate(1turn);
  }
}
@keyframes textclip {
  20%,
  100% {
    opacity: 1;
  }
  0% {
    opacity: 0;
    // background-position: 100% 0;
  }
  20% {
    opacity: 1;
    // background-position: 100% 0;
  }
}
</style>
