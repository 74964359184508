<script setup lang="ts">
import Tracker from '~/libs/Tracker';

import Utility from '~/services/Utility';
import { type WeolbuStudyDesignModule } from '~/services/main';
import { useUserProfileStore } from '~/services/profile';

const props = defineProps<{
  designModule?: WeolbuStudyDesignModule;
}>();
const { isDesktop } = useMobile();
const route = useRoute();
const targetSlide = computed(() => {
  return props.designModule?.slideList[0];
});
const moBackgroundColor = computed(() => {
  return `background: ${Utility.getHex(targetSlide.value.moBackgroundColorHex)}`;
});
const handleBannerEvent = () => {
  console.log('click! >>');
  try {
    const {
      public: { frontUrl },
    } = useRuntimeConfig();
    const profile = useUserProfileStore();
    const prevPath = sessionStorage.getItem('prevPath');
    Tracker['Click Banner']('CLASS', 'STUDY', targetSlide?.linkUrl, {
      url: `${frontUrl}${prevPath}`,
      path: route.path,
      payinguserYn: profile.profile?.paymentFunnel === 'ZERO' || !profile.profile ? 'N' : 'Y',
    });
  } catch (error: any) {
    console.log('error.toString() :>> ', error?.toString());
  }
};
</script>

<template>
  <template v-if="isDesktop">
    <NuxtLink
      :to="targetSlide?.linkUrl"
      :target="targetSlide?.linkType === 'P' ? '_self' : '_blank'"
      @click="handleBannerEvent()"
      style="width: 100%">
      <img :src="targetSlide?.pcImageUrl" class="weolbu-study-slide-img" />
    </NuxtLink>
  </template>
  <template v-else>
    <div class="weolbu-study-slide-img-container" :style="moBackgroundColor">
      <NuxtLink
        :to="targetSlide?.linkUrl"
        :target="targetSlide?.linkType === 'P' ? '_self' : '_blank'"
        @click="handleBannerEvent()"
        style="width: 100%">
        <img :src="targetSlide?.moImageUrl" class="weolbu-study-slide-img" />
      </NuxtLink>
    </div>
  </template>
</template>

<style scoped lang="scss">
.weolbu-study-slide-img-container {
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: 8px;
  @media screen and (max-width: $md) {
    border-radius: 4px;
  }
}
.weolbu-study-slide-img {
  width: 582px;
  height: 123px;
  border-radius: 8px;
  @media screen and (max-width: $md) {
    display: flex;
    width: 100%;
    max-width: 343px;
    height: 83px;
    border-radius: 4px;
    margin: auto;
    object-fit: scale-down;
  }
}
</style>
