<script setup lang="ts">
import Tracker from '~/libs/Tracker';

import Utility from '~/services/Utility';

import { useMainStore } from '../store';
import { type DesignModule, type DesignModuleSlide, DesignModuleType } from '../type';

const mainStore = useMainStore();

const props = withDefaults(
  defineProps<{
    products: DesignModule[];
    designModuleIdx: number | null;
  }>(),
  {
    products: [],
    designModuleIdx: null,
  },
);

const isEmpty = $isEmpty;

const isSubBanner2Index = (index: number) => {
  if (index === 5) return true;

  if (props.products?.length < 5) {
    return index === props.products.length - 1;
  }

  return false;
};

function openClickBannerCTA(slide: DesignModuleSlide) {
  Tracker['Click Main Mid Banner'](slide);

  Utility.openLink(slide.linkUrl, slide.linkType);
}

const slidesBottom = computed(() => {
  const list = mainStore.designModuleMainList.items.filter((v) => v.designModuleType === DesignModuleType.SLIDE);

  if (isEmpty(list)) return null;
  if (list.length > 1) {
    return list[1].slideList.filter((v) => v.useYn === 'Y');
  }
});
</script>

<template>
  <div v-if="isSubBanner2Index(props.designModuleIdx)" class="wb-main-banner">
    <Swiper
      v-if="!isEmpty(slidesBottom)"
      :loop="true"
      :modules="[SwiperAutoplay, SwiperNavigation, SwiperEffectCoverflow]"
      :effect="'coverflow'"
      :grab-cursor="true"
      :centered-slides="true"
      :slides-per-view="'auto'"
      :navigation="true"
      :coverflow-effect="{
        rotate: 0,
        stretch: -30,
        depth: 100,
        modifier: 1,
        slideShadows: false,
      }"
      :breakpoints="{
        '993': { effect: 'slide', slidesPerView: 1, spaceBetween: 0 },
      }"
      :autoplay="{
        delay: 5000,
        disableOnInteraction: true,
      }"
      :style="{ cur: 'cursor: pointer;' }">
      <!--mobile 에서는 loop 시 다음 첫번째 이미지를 무시해버리므로 두벌을 합쳐 루프 돌게 함.-->
      <SwiperSlide v-for="slide in [...slidesBottom, ...slidesBottom]" :key="slide.designSeq">
        <div class="wb-main-banner__bg">
          <div
            class="pc"
            :style="{
              background: Utility.refineColor(slide.pcBackgroundColorHex),
            }">
            <img
              v-if="slide.pcBackgroundType == 'I'"
              :src="Utility.getOptimized(slide.pcBackgroundImgUrl, 'BANNER_IMG')"
              alt="bg"
              loading="lazy" />
          </div>
          <div
            class="mo"
            :style="{
              background: Utility.refineColor(slide.moBackgroundColorHex),
            }">
            <img
              v-if="slide.moBackgroundType == 'I'"
              :src="Utility.getOptimized(slide.moBackgroundImgUrl, 'BANNER_IMG')"
              alt="bg"
              loading="lazy" />
          </div>
        </div>
        <div class="wb-main-banner__image">
          <div class="pc">
            <img
              :src="Utility.getOptimized(slide.pcImageUrl, 'BANNER_IMG')"
              alt="sub banner"
              style="cursor: pointer"
              loading="lazy"
              @click="openClickBannerCTA(slide)" />
          </div>
          <div class="mo">
            <img
              :src="Utility.getOptimized(slide.moImageUrl, 'BANNER_IMG')"
              alt="sub banner"
              style="cursor: pointer"
              loading="lazy"
              @click="openClickBannerCTA(slide)" />
          </div>
        </div>
      </SwiperSlide>
    </Swiper>
  </div>
</template>
<style lang="scss">
.wb-main {
  margin-bottom: 100px;
  @media screen and (max-width: $mobile) {
    margin-bottom: 8px;
    .wb-product-list__item {
      padding-bottom: 8px;
    }
  }
  &-keyvisual {
    position: relative;
    min-height: 400px;
    .pc,
    .mo {
      width: 100%;
      height: 100%;
    }
    .wb-inner {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 400px;
    }
    &__info {
      position: relative;
      width: 480px;
      z-index: 20;
    }
    &__tag {
      min-height: 20px;
      margin-bottom: 8px;
    }
    &__title {
      margin-bottom: 8px;
      font-weight: 700;
      font-size: 40px;
      line-height: 52px;
      color: #fff;
      word-break: keep-all;
    }
    &__text {
      color: #fff;
      font-size: 16px;
      line-height: 19px;
    }
    &__button {
      margin-top: 24px;
      margin-bottom: 20px;
      .p-button {
        width: 383px;
        border-radius: 8px;
      }
    }
    &__bg {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      .pc,
      .mo {
        width: 100%;
        height: 100%;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &__image {
      position: absolute;
      top: 0;
      right: 0;
      font-size: 0;
      line-height: 0;
      img {
        //width: 600px;
        height: 400px;
        object-fit: cover;
      }
      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.2);
        display: none;
      }
    }
    .swiper-pagination {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      left: 50%;
      bottom: 19px;
      right: auto;
      width: auto;
      margin: 0 0 0 454px;
      padding: 0 10px;
      width: 49px;
      height: 26px;
      border-radius: 26px;
      background: rgba(0, 0, 0, 0.1);
      text-align: right;
      color: rgba(255, 255, 255, 0.5);
      font-weight: 400;
      font-size: 14px;
      line-height: 26px;
      letter-spacing: 2.5px;
      span {
        margin: 0 0;
      }
      .swiper-pagination-current {
        font-weight: 600;
        color: #fff;
      }
    }
    .swiper-button-prev {
      position: absolute;
      top: auto;
      bottom: 16px;
      left: 50%;
      margin: 0 0 0 518px;
      width: 32px;
      height: 32px;
      background: rgba(0, 0, 0, 0.1) url(/icon/ArrowWhite.svg) no-repeat 50% 50%;
      border-radius: 32px;
      &:after {
        display: none;
      }
    }
    .swiper-button-next {
      position: absolute;
      top: auto;
      bottom: 16px;
      left: 50%;
      margin: 0 0 0 558px;
      width: 32px;
      height: 32px;
      background: rgba(0, 0, 0, 0.1) url(/icon/ArrowWhite.svg) no-repeat 50% 50%;
      border-radius: 32px;
      transform: rotate(180deg);
      &:after {
        display: none;
      }
    }
    @media screen and (max-width: $mobile) {
      min-height: 320px;
      .wb-inner {
        display: block;
        height: auto;
        height: 320px;
        //padding-top: 85.335%;
      }
      &__info {
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        width: auto;
        padding: 16px 16px 16px 16px;
      }
      &__tag {
        margin-bottom: 6px;
      }
      &__title {
        font-size: 22px;
        line-height: 26px;
      }
      &__text {
        color: #fff;
        font-size: 13px;
        line-height: 16px;
      }
      &__button {
        margin: 16px 0 0 0;
        .p-button {
          width: 100%;
          height: 40px;
          line-height: 1;
        }
      }
      &__image {
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        text-align: center;
        img {
          width: auto;
          height: 100%;
          //height: 320px;
        }
      }

      .swiper-pagination {
        display: flex;
        align-items: center;
        left: auto;
        top: 16px;
        bottom: auto;
        right: 16px;
        width: auto;
        margin: 0;
        padding: 0 6px;
        height: 22px;
        font-size: 12px;
        font-weight: 600;
        span {
          margin: 0 2px;
        }
        .swiper-pagination-current {
          color: #fff;
        }
      }
      .swiper-button-prev {
        display: none;
      }
      .swiper-button-next {
        display: none;
      }
    }
  }

  &-insight {
    &__title {
      font-weight: 700;
      font-size: 22px;
      color: #000;
    }
    &__list {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 30px;
    }
  }
  &-banner {
    position: relative;
    margin-bottom: 64px;
    .pc,
    .mo {
      width: 100%;
      height: 100%;
    }
    &__bg {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      z-index: 10;
      .pc,
      .mo {
        width: 100%;
        height: 100%;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &__image {
      position: relative;
      z-index: 20;
      text-align: right;
      font-size: 0;
      line-height: 0;
      picture,
      img {
        //display: block;
        //width: 1180px;
        height: 200px;
        object-fit: cover;
        image-rendering: -webkit-optimize-contrast;
        transform: translateZ(0);
        backface-visibility: hidden;
      }
    }
    .swiper {
      border-radius: 4px;
      overflow: hidden;
    }
    .swiper-slide {
      position: relative;
      height: 200px;
      .pc,
      .mo {
        font-size: 0;
        line-height: 0;
      }
    }
    .swiper-button-prev {
      left: 20px;
      width: 26px;
      height: 26px;
      margin-top: -13px;
      background: rgba(255, 255, 255, 0.2) url(/icon/ArrowWhite.svg) no-repeat 50% 50%;
      border-radius: 26px;
      &:after {
        display: none;
      }
    }
    .swiper-button-next {
      right: 20px;
      width: 26px;
      height: 26px;
      margin-top: -13px;
      background: rgba(255, 255, 255, 0.2) url(/icon/ArrowWhite.svg) no-repeat 50% 50%;
      border-radius: 26px;
      transform: rotate(180deg);
      &:after {
        display: none;
      }
    }
    @media screen and (max-width: $mobile) {
      height: 256px;
      margin: 0 -16px 52px;
      .swiper {
        border-radius: 0;
      }
      .swiper-slide {
        width: 310px;
        height: 256px;
        border-radius: 4px;
        overflow: hidden;
        picture,
        img {
          width: 310px;
          height: 256px;
        }
      }
    }
  }
  &-section {
    margin-bottom: 40px;
    @media screen and (max-width: $mobile) {
      margin-bottom: 32px;
    }
  }
  .wb-lecture__title {
    top: 2px;
    font-size: 24px;
    line-height: 29px;
    @media screen and (max-width: $mobile) {
      font-size: 18px;
      line-height: 22px;
    }
  }
  .wb-lecture .swiper {
    padding-top: 48px;
    @media screen and (max-width: $mobile) {
      padding-top: 40px;
    }
  }
  .wb-product-list__title {
    margin-bottom: 19px;
    h4 {
      font-size: 24px;
      line-height: 29px;
    }
    @media screen and (max-width: $mobile) {
      margin-bottom: 16px;
      h4 {
        font-size: 18px;
        line-height: 22px;
      }
    }
  }
}
</style>
